<template>
    <div v-if="$store.state.userAgent === 'PC'">
        <div class="header-box" @mouseleave="handleMouseleave" @mouseover="handleMouseover">
            <div class="row">
                <el-row type="flex" class="row-bg" justify="end" align="middle">
                    <el-col :span="6"></el-col>
                    <el-col ><div class="logo"><img :src="logoUrl" alt="" width="40" height="40" @click="goHome"></div></el-col>
                    <el-col ><div :class="[$route.path === '/' || $route.path === '/article' ? 'home-active' :'home']">
                        <span @click="goHome" class="label">首页</span>
                    </div></el-col>
                    <el-col v-if="appId === 'yingjing'">
                        <div :class="[$route.path === '/shop' || $route.path === '/shop-desc' ? 'home-active' : 'home']" @click="goShop" id="shop">好物推荐</div>
                    </el-col>
                    <!-- <el-col >
                        <div class="qrcode" v-if="showQRCode"><img src="../../assets/images/android-app.png" alt="" width="175" height="175"></div>
                        <div :class="[$route.path === '/download' ? 'home-active' : 'home']" @click="goDownload" id="download">下载App</div>
                    </el-col> -->
                    <el-col v-if="appId === 'mediahelper'">
                        <div :class="[$route.path === '/web' ? 'home-active' : 'home']" id="web">
                            <span @click="goWeb" class="label">AI导航</span>
                        </div>
                    </el-col>
                    <el-col v-if="appId === 'mediahelper'">
                        <div :class="[$route.path === '/chat' ? 'home-active' : 'home']" id="chat">
                            <span @click="goChat" class="label">聊天机器人</span>
                        </div>
                    </el-col>
                    <el-col v-if="appId === 'mediahelper'">
                        <div class="qrcode" v-if="showQRCode.mobile"><img src="../../assets/images/media-helper-qrcode.jpg" alt="" width="150" height="150"></div>
                        <div :class="[false ? 'home-active' : 'home']" id="mobile">
                            <span id="mobileText">移动端</span>
                        </div>
                    </el-col>
                    <!-- <el-col v-if="appId === 'mediahelper'">
                        <div :class="[$route.path === '/web' ? 'home-active' : 'home']" @click="goWeb" id="web">官方交流群</div>
                    </el-col> -->
                    <el-col>
                        <el-autocomplete
                            class="inline-input"
                            placeholder="搜索"
                            size="medium"
                            @change="handleSearch"
                            :fetch-suggestions="querySearch"
                            @select="handleSelect"
                            v-model="inputValue">
                            <i slot="suffix" class="el-input__icon el-icon-search"></i>
                        </el-autocomplete> 
                    </el-col>
                    <el-col>
                        <div :class="[$route.path === '/personal' ? 'home-active' : 'home']" v-if="$store.state.isLogin" style="cursor:default;"  id="avatar">
                            <img :src="userInfo.miniAvatarUrl" alt="" width="40" height="40" class="avatarImg" id="avatarImg">
                            <div v-if="showUserInfo" class="select" id="personal">
                                <div :class="[$route.path === '/personal' || $route.path === '/edit-personal' ? 'option-active' : 'option']" @click="goPersonal">个人主页</div>
                                <div class="option" @click="goDraftBox" id="dragbox">草稿箱</div>
                                <div class="option" @click="goOrder" id="order">我的订单</div>
                                <div class="option" @click="goAddress" id="dragbox">我的地址</div>
                                <div class="option" @click="logout" id="logout">退出登录</div>
                            </div>
                        </div>
                        <div :class="[$route.path === '/login' || $route.path === '/verify' ? 'home-active' : 'home']" @click="goLogin" v-else>
                            <span class="label">登录 | 注册</span>
                        </div>
                    </el-col>
                    <!-- <el-col ><div :class="[$route.path === '/create' ? 'home-active' : 'home']" @click="goCreate">写文章</div></el-col> -->
                </el-row>
            </div>
            <div class="header-pc-bottom"></div>
        </div>
        <logout ref="logout"/>
    </div>
</template>

<script>
import Logout from '../dialog/Logout.vue'
const seo = require('../../../config/index')

export default {
  name: 'Header',
  mounted(){
    // document.addEventListener('click',e=>{
    //     if(e.target.id !== 'download'){
    //         this.showQRCode = false
    //     }
    // })
  },
  components: {
    Logout
  },
  data(){
      return {
        inputValue: '',
        logoUrl: seo.config.logo,
        appId: seo.config.appId, // 自定义顶部菜单
        lists:[{
            id:'vision',
            value:'愿景与目标',
        },{
            id:'personal',
            value:'前往个人主页',
        },{
            id:'createArticle',
            value: '创建文章'
        },{
            id:'logout',
            value:'退出登录',
        }],
        showQRCode: {
            mobile: false,
        },
        showUserInfo: false,
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
      }
  },
  methods:{
    goHome(){
        this.$router.push('/')
        this.$bus.$emit('clearFlag')
    },
    goLogin(){
        this.$router.push('/login')
    },
    goCreate(){
        if(this.$store.state.isLogin){
            this.$router.push('/create')
        } else {
            this.$router.push('/login')
        }
    },
    goPersonal(){
        this.$router.push('/personal')
    },
    goDraftBox(){
        this.$router.push('/draft-box')
    },
    goOrder(){
        this.$router.push('/order')
    },
    goAddress(){
        this.$router.push('/address')
    },
    goShop(){
        this.$router.push('/shop')
    },
    goDownload(){
        this.$router.push('/download')
    },
    goChat(){
        this.$router.push('/chat')
    },
    goWeb(){
        this.$router.push('/web')
    },
    handleMouseover(e){
      const hoveredElement = e.target; // 获取鼠标悬停的元素
      const textContent = hoveredElement.textContent; // 获取行字的内容
      if(textContent === '移动端'){
        this.showQRCode.mobile = true
      } else if(e.target.id === 'avatarImg'){
        this.showUserInfo = true
      }
    },
    handleMouseleave(e){
      const hoveredElement = e.target; // 获取鼠标悬停的元素
      const textContent = hoveredElement.textContent; // 获取行字的内容
      if(textContent !== '移动端'){
        this.showQRCode.mobile = false
        this.showUserInfo = false
      }
    },
    handleSelect(item){
        if(this.$store.state.isLogin){
            if(item.id === 'logout'){
                this.$refs.logout.show()
            } else if(item.id === 'vision'){
                this.$router.push('/about')
            } else if(item.id === 'createArticle'){
                this.$router.push('/create')
            } else if(item.id === 'personal'){
                this.$router.push('/personal')
            }
        } else {
            this.$router.push('/login')
        }
        this.inputValue = ''
    },
    querySearch(queryString, cb) {
        var lists = this.lists;
        var results = queryString ? lists.filter(this.createFilter(queryString)) : lists;
        // 调用 callback 返回建议列表的数据
        cb(results);
    },
    handleSearch(){
        let inputVal = this.inputValue.trim()
        if(inputVal){
            this.lists = []
            if(inputVal.includes('退出登录') || inputVal.includes('logout') 
                    || inputVal.includes('登出') || inputVal.includes('取消登录')){
                this.$refs.logout.show()
            } else if(inputVal.includes('个人中心') || inputVal.includes('个人主页')){
                if(this.$store.state.isLogin){
                    this.$router.push('/personal')
                } else {
                    this.$router.push('/login')
                }
            } else {
                this.$router.push({ path: '/search-result', query: { input: inputVal }})
            }
        }
    },
    createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    },
    logout(){
        this.$refs.logout.show()
    }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.header-box {
    position: fixed;
    // display: flex;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 50px;
    background: #fefefe;
    z-index: 1000;
    // border-bottom: 1px rgba(0,0,0,0.05) solid;
    box-shadow: 0px 0px 10px 0px #ecf1f8;
    .row {
        // display: flex;
        // align-items: center;
        .logo {
            img {
                border-radius: 10px;
                margin: 5px 0px 0;
                cursor: pointer;
            }
        }
        .home {
            font-size: 1rem;
            margin: 0 0px;
            color: rgba(0,0,0,0.8);
        }
        .home-active {
            font-size: 1rem;
            margin: 0 0px;
            color: #ec745b;
        }
        .label {
            cursor: pointer;
        }
        .avatarImg {
            border-radius: 30px;
        }
        .select {
            position: absolute;
            border: 1px rgba(0,0,0,0.1) solid;
            min-width: 6.5rem;
            border-radius: 5px;
            background: #fff;
            text-align: left;
            font-size: 14px;
            margin: 5px 0 0 40px;
            font-weight: 100;
            z-index: 1000;
            cursor: pointer;
            .option {
                border-bottom: 1px rgba(0,0,0,0.1) solid;
                height: 40px;
                padding: 0 20px;
                line-height: 40px;
                color: #000;
            }
            .option-active {
                border-bottom: 1px rgba(0,0,0,0.1) solid;
                height: 40px;
                padding: 0 20px;
                line-height: 40px;
                color: #ec745b;
            }
        }
        .qrcode {
            position: absolute;
            z-index: 1000;
            img {
                margin: 40px 0 0 20px;
            }
        }
    }
    .header-pc-bottom {
        position: fixed;
        z-index: 999;
        top: 50px;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        height: 15px;
        max-height: 15px;
        box-sizing: border-box;
        background: #f3f3f3;
    }
}
::v-deep .el-input--medium .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-radius: 50px;
    caret-color: #409EFF;
}
</style>
