<template>
    <div v-if="$store.state.userAgent === 'Mobile'">
        <div class="nav-create-box" v-if="$route.path === '/create'">
            <div class="header-create" v-if="$store.state.showLabel">
                <div @click="cancelSelect" class="cancel">取消</div>
                <div>
                    <van-loading color="#ea6f7b" size="24px" v-show="isLoading"/>
                    <span v-if="!isLoading" @click="addLabel">添加</span>
                </div>
            </div>
            <div class="header-create" v-else id="headerCreate">
                <div @click="cancel" class="cancel">取消</div>
                <div>
                    <van-loading color="#ea6f7b" size="24px" v-show="isLoading"/>
                    <span v-if="!isLoading" @click="create">发布</span>
                </div>
            </div>
        </div>
        <div class="nav-box" v-else-if="$route.path === '/draft-box' || $route.path === '/pay' || $route.path === '/create-address' && title === '创建地址' 
            || $route.path === '/address' || $route.path === '/pay-result' || $route.path === '/order' || $route.path === '/booking' 
            || $route.path === '/order-desc' || $route.path === '/express' || $route.path === '/output' || $route.path === '/feedback'">
            <div class="header-draft">
                <img src="../../assets/images/back.png" alt="" width="30" height="30" @click="goBack">
                <span>{{ title }}</span>
                <span style="color: rgba(255,255,255,0);">more</span>
            </div>
        </div>
        <div class="nav-box" v-else-if="$route.path === '/create-address' && title === '修改地址'">
            <div class="header-draft">
                <img src="../../assets/images/back.png" alt="" width="30" height="30" @click="goBack">
                <span>{{ title }}</span>
                <span @click="handleDelete">删除</span>
            </div>
        </div>
        <div class="nav-box-chat" v-else-if="$route.path === '/chat' && (title === '聊个小天' || title === '努力解答中...') || $route.path === '/customer-service'">
            <div class="header-chat">
                <img src="../../assets/images/back-white.png" alt="" width="22" height="22" @click="goBack">
                <span>{{ title }}</span>
                <div style="width: 22px;"></div>
            </div>
        </div>
        <div class="nav-box" v-else>
            <div class="header">
                <img src="../../assets/images/back.png" alt="" width="30" height="30" @click="goBack">
                <img src="../../assets/images/more.png" alt="" width="30" height="30" @click="more" v-if="isLoginAuthor">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Nav',
    created(){
        this.$bus.$on('showLoading',this.showLoading)
    },
    mounted(){
        window.addEventListener('scroll', this.scrollAndFixed);
    },
    destroyed(){
        window.removeEventListener('scroll', this.scrollAndFixed)
    },
    components: {
    },
    data(){
        return {
            isLoading: false,
        }
    },
    methods:{
        showLoading(){
            this.isLoading = !this.isLoading
        },
        cancel(){
            this.$bus.$emit('handleCancel')
        },
        cancelSelect(){
            this.$store.commit('Set_ShowLabel_State', false)
            this.$bus.$emit('cancelSelect')
        },
        goBack(){
            if(this.$route.query.share){
                this.$router.push('/')
            } else {
                if(this.$route.path === '/order'){
                    if(sessionStorage.getItem('tabId')){
                        sessionStorage.removeItem('tabId')
                    }
                    if(sessionStorage.getItem('fromPath') && (sessionStorage.getItem('fromPath').includes('personal')
                        || sessionStorage.getItem('fromPath').includes('activesvip')
                        || sessionStorage.getItem('fromPath').includes('partner'))){
                        sessionStorage.removeItem('fromPath')
                        this.$router.push('/personal')
                    } else {
                        this.$router.push('/')
                        // this.$router.push('/shop')
                    }
                } else if(this.$route.path === '/create-address'){
                    if(this.$route.query.from){
                        this.$router.push({ path:'/address', query:{ aid: 'xx', from: this.$route.query.from } })
                    } else {
                        if(this.$route.query.oid){
                            this.$router.push({ path:'/address', query:{ aid: 'xx', oid: this.$route.query.oid } })
                        } else {
                            this.$router.push({ path:'/address', query:{ aid: 'xx' } })
                        }
                    }
                } else if(this.$route.path === '/address'){
                    if(this.$route.query.aid && !this.$route.query.from){
                        if(this.$route.query.oid){
                            this.$router.push({ path:'/pay', query:{ oid: this.$route.query.oid } })
                        } else {
                            this.$router.push('/pay')
                        }
                    } else if(this.$route.query.from){
                        this.$router.push('/personal')
                    } else {
                        // 目前是回到/personal页
                        this.$router.back()
                    }
                } else if(this.$route.path === '/pay'){
                    sessionStorage.removeItem('fromPath')
                    if(this.$route.query.gid){
                        this.$router.push({ path:'/shop-desc', query:{ gid: this.$route.query.gid } })
                        sessionStorage.setItem('fromPath', '/pay')
                    } else {
                        this.$router.push('/cart')
                    }
                }  else if(this.$route.path === '/order-desc'){
                    this.$router.push({ path:'/order', query:{ tabId: this.$route.query.tabId } })
                } else if(this.$route.path === '/pay-result'){
                    if(this.$route.query.gid){
                        this.$router.push({ path:'/shop-desc', query:{ gid: this.$route.query.gid } })
                    } else if(this.$route.query.memberId){
                        this.$router.push({ path:'/personal'})
                    } else if(this.$route.query.partnerId){
                        this.$router.push({ path:'/personal'})
                    } else {
                        this.$router.push({ path:'/shop'})
                    }
                } else if(this.$route.path === '/output'){
                    this.$router.push({ path:'/prompt'})
                } else if(this.$route.path === '/chat'){
                    this.$router.push({ path:'/'})
                } else if(this.$route.path === '/article' && sessionStorage.getItem('fromPath') === 'verify'){
                    sessionStorage.removeItem('fromPath')
                    this.$router.push({ path:'/'})
                } else {
                    this.$router.back()
                }
            }
        },
        create(){
            this.$bus.$emit('handleCreate')
        },
        addLabel(){
            this.$store.commit('Set_ShowLabel_State', false)
            this.$bus.$emit('addLabel')
        },
        more(){
            this.$bus.$emit('more')
        },
        handleDelete(){
            this.$emit('handleDelete')
        }
    },
    props:{
        isLoginAuthor:{
            type: Boolean,
            default: false
        },
        title:{
            type: String,
            default: ''
        },
        urlParams:{
            type:String,
            default: '',
        }
    }
}
</script>

<style scoped lang="scss">
.nav-box {
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    height: 50px;
    width: 100%;
    position: fixed;
    top:0;
    z-index: 1;
}
.nav-box-chat {
    background-image: linear-gradient(-20deg,#fc6076,#ff9a44);
    border-bottom: 1px solid rgba(0,0,0,0.05);
    height: 50px;
    width: 100%;
    position: fixed;
    top:0;
    z-index: 100;
    color: #eee;
}
.nav-create-box {
    background: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    height: 50px;
    position: fixed;
    -webkit-overflow-scroll:touch;
    z-index: 1;
    left: 0;
    right: 0;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.header-draft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.header-chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 100%;
}
.header-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    .cancel {
        color: #969699;
    }
}
.header-create :nth-child(2){
    color: #ec745b;
}
.van-loading {
    position: relative !important;
    left: 0;
    top: 0;
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    font-size: 0;
    vertical-align: middle;
    -ms-transform: none;
}
</style>
