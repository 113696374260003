import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../common.css'
import nav from '../src/components/nav'
import Vant from 'vant';
import axios from 'axios';
import 'vant/lib/index.css';
import wx from "weixin-js-sdk";
// import Vconsole from 'vconsole'
import VueCropper from 'vue-cropper'
import { Input, Row, Col, Autocomplete, Carousel,  CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import _ from 'lodash'
import VueLazyload from 'vue-lazyload'
// import FastClick from 'fastclick';

Vue.use(Vant)
Vue.use(VueCropper)
Vue.use(Autocomplete)
Vue.use(Input);
Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(VueLazyload);
Vue.prototype.$bus = new Vue()
Vue.prototype.$axios = axios
Vue.prototype.$wx = wx;
Vue.prototype._ = _;
Vue.config.productionTip = false
Vue.component("index-nav",nav)
// FastClick.attach(document.body);
// 打开移动端控制台
  // const vConsole = new Vconsole()
  // Vue.use(vConsole)

// seo标题、关键词处理
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});


// 页面跳转默认回顶部
router.afterEach((to,from,next) => {
  setTimeout(()=>{
    window.scrollTo(0,0);
  },0)
  next
})

const loadimage = require('./assets/images/default-shop-desc-swiper.png')
const errorimage = require('./assets/images/default-shop-desc-swiper.png')

// 配置项
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
