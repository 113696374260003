import Vue from 'vue'
import VueRouter from 'vue-router'
const seo = require("../../config/index")

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => {
      if(seo.config.mode === 'shop'){
        return import('../views/shop/index.vue')
      } else {
        return import('../views/Home.vue')
      }
    },
    meta:{
      keepAlive: true,
      title: seo.config.seoPage.homePage.title,
      content:{
        keywords: seo.config.seoPage.homePage.keywords,
        description: seo.config.seoPage.homePage.description
      },
      requireAuth: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta:{
      requireAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue'),
    meta:{
      requireAuth: false
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('../views/login/Verify.vue'),
    meta:{
      requireAuth: false
    }
  },
  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import('../views/login/Invitation.vue'),
    meta:{
      requireAuth: false
    }
  },
  {
    path: '/article',
    name: 'Article',
    component: () => import('../views/Article.vue'),
    meta:{
      requireAuth: false
    }
  },
  {
    path: '/create',
    name: 'CreateArticle',
    component: () => import('../views/CreateArticle.vue'),
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/search-result',
    name: 'SearchResult',
    component: () => import('../views/search/index.vue'),
    meta:{
      keepAlive: false,
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/Search.vue'),
    meta:{ 
      keepAlive: false,
    }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => {
      if(seo.config.mode === 'shop'){
        return import('../views/personal/ShopIndex.vue')
      } else {
        return import('../views/personal/index.vue')
      }
    },
    meta:{ 
      keepAlive: true,
      requireAuth: true
    }
  },
  {
    path: '/author',
    name: 'Author',
    component: () => import('../views/personal/index.vue'),
    meta:{ 
      keepAlive: true,
      requireAuth: false
    }
  },
  {
    path: '/edit-personal',
    name: 'EditPersonal',
    component: () => import('../views/personal/EditPersonal.vue'),
    meta:{
      requireAuth: true
    },
    children:[
      {
        path:'nickname',
        name: 'EditNickname',
        component: () => import('../views/personal/EditNickname.vue'),
        meta:{
          requireAuth: true
        },
      },{
        path:'intro',
        name: 'EditIntro',
        component: () => import('../views/personal/EditIntro.vue'),
        meta:{
          requireAuth: true
        },
      }
    ]
  },
  {
    path: '/list',
    name: 'FollowList',
    component: () => import('../views/personal/FollowList.vue'),
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/about/About.vue'),
    meta:{
      title: seo.config.seoPage.aboutPage.title,
      content:{
        keywords: seo.config.seoPage.aboutPage.keywords,
        description: seo.config.seoPage.aboutPage.description
      },
      requireAuth: false
    },
    children:[
      {
        path:'answer',
        name: 'Answer',
        component: () => import('../views/about/Answer.vue'),
      },{
        path:'vision',
        name: 'Vision',
        component: () => import('../views/about/Vision.vue'),
      },{
        path:'copyright',
        name: 'Copyright',
        component: () => import('../views/about/Copyright.vue'),
      },{
        path:'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/about/PrivacyPolicy.vue'),
      },{
        path:'user-agreement',
        name: 'UserAgreement',
        component: () => import('../views/about/UserAgreement.vue'),
      }
    ]
  },{
    path:'/download',
    name: 'Download',
    component: () => import('../views/about/Download.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/activesvip',
    name: 'Activesvip',
    component: () => import('../views/personal/Activesvip.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/draft-box',
    name: 'DraftBox',
    component: () => import('../views/draft/DraftBox.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/booking',
    name: 'Booking',
    component: () => import('../views/shop/Booking.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/shop',
    name: 'Shop',
    component: () => import('../views/shop/index.vue'),
    redirect:'/',
    meta:{
      keepAlive: true,
      title: seo.config.seoPage.shopPage.title,
      content:{
        keywords: seo.config.seoPage.shopPage.keywords,
        description: seo.config.seoPage.shopPage.description
      },
      requireAuth: false
    }
  },{
    path:'/shop-desc',
    name: 'Description',
    component: () => import('../views/shop/Description.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/order',
    name: 'Order',
    component: () => import('../views/order/index.vue'),
    meta:{
      requireAuth: true,
      keepAlive: true,
    }
  },{
    path:'/pay',
    name: 'Pay',
    component: () => import('../views/order/Pay.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/order-desc',
    name: 'OrderDetails',
    component: () => import('../views/order/OrderDetails.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/pay-result',
    name: 'PayResult',
    component: () => import('../views/order/PayResult.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/address',
    name: 'Address',
    component: () => import('../views/order/Address.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/create-address',
    name: 'CreateAddress',
    component: () => import('../views/order/CreateAddress.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/logo',
    name: 'Logo',
    component: () => import('../components/logo/index.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/express',
    name: 'Express',
    component: () => import('../views/order/Express.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/chat',
    name: 'Chat',
    component: () => import('../views/chat/index.vue'),
    meta:{
      keepAlive: true,
      title: seo.config.seoPage.chatPage.title,
      content:{
        keywords: seo.config.seoPage.chatPage.keywords,
        description: seo.config.seoPage.chatPage.description
      },
      requireAuth: true
    }
  },{
    path:'/point-detail',
    name: 'PointDetail',
    component: () => import('../views/personal/PointDetail.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/coupon',
    name: 'Coupon',
    component: () => import('../views/personal/Coupon.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/coupon-more',
    name: 'CouponMore',
    component: () => import('../views/personal/CouponMore.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/claim-coupon',
    name: 'ClaimCoupon',
    component: () => import('../views/personal/ClaimCoupon.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/prompt',
    name: 'Prompt',
    component: () => import('../views/prompt/index.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/output',
    name: 'Output',
    component: () => import('../views/prompt/Output.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/wallet',
    name: 'MyWallet',
    component: () => import('../views/personal/MyWallet.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/partner',
    name: 'PartnerCenter',
    component: () => import('../views/personal/PartnerCenter.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/partner-admin',
    name: 'PartnerAdmin',
    component: () => import('../views/partner/index.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/partner-income-list',
    name: 'IncomeList',
    component: () => import('../views/partner/IncomeList.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/partner-moments-text',
    name: 'MomentsText',
    component: () => import('../views/partner/MomentsText.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/partner-poster',
    name: 'Poster',
    component: () => import('../views/partner/Poster.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/partner-user-list',
    name: 'UserList',
    component: () => import('../views/partner/UserList.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/qrcode',
    name: 'QRCode',
    component: () => import('../views/partner/QRCode.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/web',
    name: 'Web',
    component: () => import('../views/web/index.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/cart',
    name: 'Cart',
    component: () => import('../views/shop/Cart.vue'),
    meta:{
      keepAlive: true,
      requireAuth: false
    }
  },{
    path:'/category',
    name: 'Category',
    component: () => import('../views/category/index.vue'),
    meta:{
      keepAlive: true,
      requireAuth: false
    }
  },{
    path:'/intro',
    name: 'Intro',
    component: () => import('../views/about/Intro.vue'),
    meta:{
      requireAuth: false
    }
  },{
    path:'/customer-service',
    name: 'CustomerService',
    component: () => import('../views/chat/CustomerService.vue'),
    meta:{
      requireAuth: true
    }
  },{
    path:'/feedback',
    name: 'Feedback',
    component: () => import('../views/chat/Feedback.vue'),
    meta:{
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  // base: '/yingjidaohang',
  base: '/',
  // base: process.env.VUE_APP_BASE_API,
  routes,
})

router.beforeEach((to, from, next) => {
  let isLogin = localStorage.getItem('token') ? true : false
  if(seo.config.mode === 'shop'){
    if (to.meta.requireAuth) {
      if(isLogin){
        next();
      } else {
        next('/login');
      }
    } else {
      next();
    }
  } else {
    if (to.path !== '/login' && to.path !== '/verify' && to.path !== '/invitation' && to.path !== '/intro'
     && to.path !== '/about/user-agreement' && to.path !== '/about/privacy-policy' && !isLogin) {
      next('/login');
    } else {
      next();
    }
  }
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
