import request from '@/utils/request'
// 短信验证码
export function sms (data) {
  return request({
    url: '/smsCode',
    method: 'post',
    data
  })
}

// 物流信息
export function express (data) {
  return request({
    url: '/express',
    method: 'post',
    data
  })
}

// 路由查询接口-速运类API
export function queryRECERoutes (data) {
  return request({
    url: '/express/queryRECERoutes',
    method: 'post',
    data
  })
}

// 创建文章
export function createArticle (data) {
  return request({
    url: '/createArticle',
    method: 'post',
    data
  })
}

// 创建草稿
export function createDraft (data) {
  return request({
    url: '/draft/createDraft',
    method: 'post',
    data
  })
}

// 查询所有文章
export function queryAllArticle (data) {
  return request({
    url: '/home/queryAllArticle',
    method: 'post',
    data
  })
}

// import Qs from 'qs'
// // 查询所有文章
// export function queryAllArticle (data) {
//   return request({
//     url: '/home/queryAllArticle',
//     method: 'post',
//     data: Qs.stringify(data)
//   })
// }

// 查询个人中心所有文章
export function queryAllArticleByUid (data) {
  return request({
    url: '/personal/queryAllArticleByUid',
    method: 'post',
    data
  })
}

// 查询个人中心所有草稿
export function queryAllDraftByUid (data) {
  return request({
    url: '/draft/queryAllDraftByUid',
    method: 'post',
    data
  })
}

// 根据tid删除一篇文章
export function deleteArticleByTid (data) {
  return request({
    url: '/personal/deleteArticleByTid',
    method: 'post',
    data
  })
}

// 根据did删除一个草稿
export function deleteDraftByDid (data) {
  return request({
    url: '/draft/deleteDraftByDid',
    method: 'post',
    data
  })
}

// 根据tid更新一个草稿
export function updateDraftByDid (data) {
  return request({
    url: '/draft/updateDraftByDid',
    method: 'post',
    data
  })
}

// 查询文章详情
export function queryArticleById (data) {
  return request({
    url: '/home/queryArticleById',
    method: 'post',
    data
  })
}

// 查询草稿详情
export function queryDraftById (data) {
  return request({
    url: '/draft/queryDraftById',
    method: 'post',
    data
  })
}

// 模糊查询文章
export function queryArticleByInputVal (data) {
  return request({
    url: '/home/queryArticleByInputVal',
    method: 'post',
    data
  })
}

// 模糊查询商品
export function queryGoodsByInputVal (data) {
  return request({
    url: '/shop/queryGoodsByInputVal',
    method: 'post',
    data
  })
}

// 上传文件
export function upload (data) {
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}

// 删除文件
export function deleteUpload (data) {
  return request({
    url: '/deleteUpload',
    method: 'post',
    data
  })
}

// 注册新用户
export function createUser (data) {
  return request({
    url: '/users/createUser',
    method: 'post',
    data
  })
}

// 登录获取token
export function login (data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 查询用户信息
export function queryUserByPhoneNum (data) {
  return request({
    url: '/users/queryUserByPhoneNum',
    method: 'post',
    data
  })
}

// 查询用户信息
export function queryUserByUid (data) {
  return request({
    url: '/users/queryUserByUid',
    method: 'post',
    data
  })
}

// 更新昵称
export function updateNickname (data) {
  return request({
    url: '/users/updateNickname',
    method: 'post',
    data
  })
}

// 更新个人简介
export function updateIntro (data) {
  return request({
    url: '/users/updateIntro',
    method: 'post',
    data
  })
}

// 更新用户头像
export function updateAvatarUrl (data) {
  return request({
    url: '/users/updateAvatarUrl',
    method: 'post',
    data
  })
}

// 更新个人中心背景
export function updateBGUrl (data) {
  return request({
    url: '/users/updateBGUrl',
    method: 'post',
    data
  })
}

// 关注用户
export function followAuthor (data) {
  return request({
    url: '/users/followAuthor',
    method: 'post',
    data
  })
}

// 取消关注用户
export function unfollowAuthor (data) {
  return request({
    url: '/users/unfollowAuthor',
    method: 'post',
    data
  })
}

// 文章点赞
export function like (data) {
  return request({
    url: '/home/like',
    method: 'post',
    data
  })
}

// 文章取消点赞
export function unlike (data) {
  return request({
    url: '/home/unlike',
    method: 'post',
    data
  })
}

// 关注列表
export function followList (data) {
  return request({
    url: '/personal/followList',
    method: 'post',
    data
  })
}

// 粉丝列表
export function fansList (data) {
  return request({
    url: '/personal/fansList',
    method: 'post',
    data
  })
}

// 文章评论
export function comment (data) {
  return request({
    url: '/home/comment',
    method: 'post',
    data
  })
}

// 查询文章评论
export function queryCommentList (data) {
  return request({
    url: '/home/queryCommentList',
    method: 'post',
    data
  })
}

// 评论点赞、回复点赞
export function commentLike (data) {
  return request({
    url: '/home/commentLike',
    method: 'post',
    data
  })
}

// 取消评论点赞、取消回复点赞
export function commentUnLike (data) {
  return request({
    url: '/home/commentUnLike',
    method: 'post',
    data
  })
}

// 删除评论
export function deleteComment (data) {
  return request({
    url: '/home/deleteComment',
    method: 'post',
    data
  })
}

// 删除回复
export function deleteReply (data) {
  return request({
    url: '/home/deleteReply',
    method: 'post',
    data
  })
}

// 获取公众号信息
export function wxSdk (data) {
  return request({
    url: '/wxSdk',
    method: 'post',
    data
  })
}

// 获取购物页-轮播图
export function querySwiperByStatus (data) {
  return request({
    url: '/swiper/querySwiperByStatus',
    method: 'post',
    data
  })
}

// 获取全部商品列表
export function queryOnSaleGoodsByUid (data) {
  return request({
    url: '/shop/queryOnSaleGoodsByUid',
    method: 'post',
    data
  })
}

// 获取商品详情
export function queryGoodsById (data) {
  return request({
    url: '/shop/queryGoodsById',
    method: 'post',
    data
  })
}

// 创建地址
export function createAddress (data) {
  return request({
    url: '/address/createAddress',
    method: 'post',
    data
  })
}

// 查询默认地址
export function querydefaultAddressById (data) {
  return request({
    url: '/address/querydefaultAddressById',
    method: 'post',
    data
  })
}

// 查询登录用户所有地址
export function queryAllAddressByUid (data) {
  return request({
    url: '/address/queryAllAddressByUid',
    method: 'post',
    data
  })
}

// 更新指定地址
export function updateAddressByAid (data) {
  return request({
    url: '/address/updateAddressByAid',
    method: 'post',
    data
  })
}

// 删除指定地址
export function deleteAddressByAid (data) {
  return request({
    url: '/address/deleteAddressByAid',
    method: 'post',
    data
  })
}

// 创建订单
export function createOrder (data) {
  return request({
    url: '/order/createOrder',
    method: 'post',
    data
  })
}

// 查询订单详情
export function queryOrderById (data) {
  return request({
    url: '/order/queryOrderById',
    method: 'post',
    data
  })
}

// 查询登录用户所有订单
export function queryAllOrderByUid (data) {
  return request({
    url: '/order/queryAllOrderByUid',
    method: 'post',
    data
  })
}

// 查询登录用户一种状态的所有订单
// 待支付、待收货、待确认、售后等等
export function queryKindStatusOrderByUid (data) {
  return request({
    url: '/order/queryKindStatusOrderByUid',
    method: 'post',
    data
  })
}

// 更新指定订单
export function updateOrderByOid (data) {
  return request({
    url: '/order/updateOrderByOid',
    method: 'post',
    data
  })
}

// 删除指定订单
export function deleteOrderByOid (data) {
  return request({
    url: '/order/deleteOrderByOid',
    method: 'post',
    data
  })
}

// 获取用户IP
export function getIP (data) {
  return request({
    url: '/userstas/getIP',
    method: 'post',
    data
  })
}

// 添加访客数据
export function createUserStas (data) {
  return request({
    url: '/userstas/createUserStas',
    method: 'post',
    data
  })
}

// 获取微信用户的唯一id
export function getOpenId (data) {
  return request({
    url: '/pay/getOpenId',
    method: 'post',
    data
  })
}

// 发起微信支付
export function submitWechatPay (data) {
  return request({
    url: '/pay/submitWechatPay',
    method: 'post',
    data
  })
}

// 发起微信支付退款
export function refundWechatPay (data) {
  return request({
    url: '/pay/refundWechatPay',
    method: 'post',
    data
  })
}

// chatgpt对话接口
export function chatgpt (data) {
  return request({
    url: '/chat/chatgpt',
    method: 'post',
    data
  })
}

// sparkAssitantChat对话接口
export function sparkAssitantChat (data) {
  return request({
    url: '/chat/sparkAssitantChat',
    method: 'post',
    data
  })
}

// 增加一条聊天记录
export function addchat (data) {
  return request({
    url: '/chat/addchat',
    method: 'post',
    data
  })
}

// 查询用户uid下的所有聊天记录
export function querychat (data) {
  return request({
    url: '/chat/querychat',
    method: 'post',
    data
  })
}

// 添加积分明细
export function addPointsDetail (data) {
  return request({
    url: '/point/addPointsDetail',
    method: 'post',
    data
  })
}

// 查询积分明细
export function queryPointsDetailById (data) {
  return request({
    url: '/point/queryPointsDetailById',
    method: 'post',
    data
  })
}

// 更新用户会员权益
export function updateVipAsset (data) {
  return request({
    url: '/asset/updateVipAsset',
    method: 'post',
    data
  })
}

// 更新用户合伙人权益
export function updatePartnerAsset (data) {
  return request({
    url: '/asset/updatePartnerAsset',
    method: 'post',
    data
  })
}

// 查询优惠券
export function queryCouponById (data) {
  return request({
    url: '/coupon/queryCouponById',
    method: 'post',
    data
  })
}

// 根据优惠券码查询优惠券
export function queryCouponByCode (data) {
  return request({
    url: '/coupon/queryCouponByCode',
    method: 'post',
    data
  })
}

// 核销优惠券
export function redeemCouponsByCoid (data) {
  return request({
    url: '/coupon/redeemCouponsByCoid',
    method: 'post',
    data
  })
}

// 查询一张待分配优惠券
export function queryOneNoUidCoupon (data) {
  return request({
    url: '/coupon/queryOneNoUidCoupon',
    method: 'post',
    data
  })
}

// 给用户发放优惠券
export function couponsDistributionByCode (data) {
  return request({
    url: '/coupon/couponsDistributionByCode',
    method: 'post',
    data
  })
}

// 查询用户专属域名
export function queryDomainByUid (data) {
  return request({
    url: '/domain/queryDomainByUid',
    method: 'post',
    data
  })
}

// 查询合伙人收益明细
export function queryWalletByUid (data) {
  return request({
    url: '/wallet/queryWalletByUid',
    method: 'post',
    data
  })
}

// 建立合伙人关系-未支付状态
export function addPartnerNoPaid (data) {
  return request({
    url: '/partner/addPartnerNoPaid',
    method: 'post',
    data
  })
}

// 查询建立关系的合伙人的推广码
export function queryPromotionCode (data) {
  return request({
    url: '/partner/queryPromotionCode',
    method: 'post',
    data
  })
}

// 查询邀请码是否存在
export function queryCodeNoUsed (data) {
  return request({
    url: '/invitation/queryCodeNoUsed',
    method: 'post',
    data
  })
}

// 更新邀请码
export function updateInvitationByCode (data) {
  return request({
    url: '/invitation/updateInvitationByCode',
    method: 'post',
    data
  })
}

// 加入购物车
export function createCart (data) {
  return request({
    url: '/cart/createCart',
    method: 'post',
    data
  })
}

// 查询所有购物车商品
export function queryAllCartByUid (data) {
  return request({
    url: '/cart/queryAllCartByUid',
    method: 'post',
    data
  })
}

// 更新购物车商品
export function updateCartByCartId (data) {
  return request({
    url: '/cart/updateCartByCartId',
    method: 'post',
    data
  })
}

// 删除购物车商品
export function deleteCartByCartId (data) {
  return request({
    url: '/cart/deleteCartByCartId',
    method: 'post',
    data
  })
}

// 清空购物车
export function deleteAllCartGoodsByUid (data) {
  return request({
    url: '/cart/deleteAllCartGoodsByUid',
    method: 'post',
    data
  })
}

// 根据shop_id查询所有类别
export function queryAllCategoryByShopId (data) {
  return request({
    url: '/category/queryAllCategoryByShopId',
    method: 'post',
    data
  })
}

// 根据shop_id查询所有一级类别
export function queryAllFirstCategoryByShopId (data) {
  return request({
    url: '/category/queryAllFirstCategoryByShopId',
    method: 'post',
    data
  })
}

// 根据shopId和firstCategoryId查询所有一级类别
export function queryAllSecondCategoryById (data) {
  return request({
    url: '/category/queryAllSecondCategoryById',
    method: 'post',
    data
  })
}

// 查询某一类的所有上架商品
export function querOnSaleCategoryGoodsByUid (data) {
  return request({
    url: '/shop/querOnSaleCategoryGoodsByUid',
    method: 'post',
    data
  })
}

// 增加一条 客服 聊天记录
export function addCustomerServiceChat (data) {
  return request({
    url: '/service/addCustomerServicechat',
    method: 'post',
    data
  })
}

// 查询用户uid下的所有跟 客服 的聊天记录
export function queryCustomerServiceChat (data) {
  return request({
    url: '/service/queryCustomerServicechat',
    method: 'post',
    data
  })
}

// 聊天消息标记为已读状态
export function updateChatStatusByServiceId (data) {
  return request({
    url: '/service/updateChatStatusByServiceId',
    method: 'post',
    data
  })
}

// 购物车商品总数
export function queryCartGoodsTotalAmount (data) {
  return request({
    url: '/cart/queryCartGoodsTotalAmount',
    method: 'post',
    data
  })
}

// 查询一种状态的所有订单数
export function querStatusOrderGoodsTotalAmount (data) {
  return request({
    url: '/order/querStatusOrderGoodsTotalAmount',
    method: 'post',
    data
  })
}

// 创建反馈
export function addFeedback (data) {
  return request({
    url: '/feedback/addFeedback',
    method: 'post',
    data
  })
}

// 根据uid查询该用户所有反馈
export function queryAllFeedbackByUid (data) {
  return request({
    url: '/feedback/queryAllFeedbackByUid',
    method: 'post',
    data
  })
}

// 通过uid和status查询某种状态下的所有反馈
export function queryKindsFeedback (data) {
  return request({
    url: '/feedback/queryKindsFeedback',
    method: 'post',
    data
  })
}

// 查询所有资讯标签
export function queryAllLabel (data) {
  return request({
    url: '/label/queryAllLabel',
    method: 'post',
    data
  })
}