<template>
    <div class="home-footer" id="home-footer">
      <div class="home-page" @click="goHomePage">
        <div>
          <img src="../../assets/images/home-selected.png" alt="" width="26px" height="26px" v-if="$route.path === '/'">
          <img src="../../assets/images/home.png" alt="" width="26px" height="26px" v-else>
          <div class="text" >首页</div>
        </div>
      </div>
      <!-- <div class="divider"></div>
      <div class="home-page" @click="goBookingPage">
        <img src="../../assets/images/shop-selected.png" alt="" width="21px" height="21px" v-if="$route.path.includes('/booking')">
        <img src="../../assets/images/shop.png" alt="" width="25px" height="25px" v-else>
        <span v-if="$route.path.includes('/shop')">预购</span>
        <span v-else>预购</span>
      </div> -->
      <div class="divider" v-if="mode === 'shop'"></div>
      <div class="home-page" @click="goCategoryPage"  v-if="mode === 'shop'">
        <div>
          <img src="../../assets/images/category.png" alt="" width="26px" height="26px" v-if="$route.path.includes('/category')">
          <img src="../../assets/images/category-o.png" alt="" width="26px" height="26px" v-else>
          <div class="text" v-if="$route.path.includes('/category')">分类</div>
          <div class="text" v-else>分类</div>
        </div>
      </div>
      <div class="divider"></div>
      <!-- <div class="home-page" @click="goShopPage"  v-if="mode === 'shop'">
        <img src="../../assets/images/bag.png" alt="" width="22px" height="22px" v-if="$route.path.includes('/shop')">
        <img src="../../assets/images/bag-o.png" alt="" width="22px" height="22px" v-else>
        <span v-if="$route.path.includes('/shop')">好物</span>
        <span v-else>好物</span>
      </div> -->
      <div class="home-page" @click="goCartPage"  v-if="mode === 'shop'">
        <badge :amount="cartAmount" :type="'cart'">
          <template v-slot:amount>
            <img src="../../assets/images/cart.png" alt="" width="26px" height="26px" v-if="$route.path.includes('/cart')">
            <img src="../../assets/images/cart-o.png" alt="" width="26px" height="26px" v-else>
            <div class="text" v-if="$route.path.includes('/cart')">购物车</div>
            <div class="text" v-else>购物车</div>
          </template>
        </badge>
      </div>
      <!-- <div class="home-page" @click="goChatPage" v-if="appId === 'mediahelper'">
        <img src="../../assets/images/robot-fill.png" alt="" width="22px" height="22px" v-if="$route.path.includes('/chat')">
        <img src="../../assets/images/robot.png" alt="" width="22px" height="22px" v-else>
        <span v-if="$route.path.includes('/chat')">小天AI</span>
        <span v-else>小天AI</span>
      </div> -->
      <div class="home-page" @click="goPrompt" v-if="appId === 'mediahelper'">
        <div>
          <img src="../../assets/images/robot-fill.png" alt="" width="26px" height="26px" v-if="$route.path.includes('/prompt')">
          <img src="../../assets/images/robot.png" alt="" width="26px" height="26px" v-else>
          <div class="text" v-if="$route.path.includes('/prompt')">AI工具</div>
          <div class="text" v-else>AI工具</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="home-page" style="width: 55%;padding: 0 5px;" @click="goWeb" v-if="appId === 'mediahelper'">
        <div>
          <img src="../../assets/images/explore-selected.png" alt="" width="27px" height="27px" v-if="$route.path.includes('/web')">
          <img src="../../assets/images/explore.png" alt="" width="27px" height="27px" v-else>
          <div class="text" v-if="$route.path.includes('/web')">AI导航</div>
          <div class="text" v-else>AI导航</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="user-center"  @click="goUserCenter">
        <div>          
          <img src="../../assets/images/user-center-selected.png" alt="" width="24px" height="24px" v-if="$route.path.includes('/personal')">
          <img src="../../assets/images/user-center.png" alt="" width="26px" height="26px" v-else>
          <div class="text">我的</div>
        </div>
      </div>
      <!-- 全面屏适配 -->
      <div class="safe-area"></div>
    </div>
</template>

<script>
const seo = require('../../../config/index')
import badge from '../badge/index.vue'
// import { comment } from '@/api/api'
export default {
  name: 'Main',
  activated(){
    this.isLogin = localStorage.getItem('token') ? true : false
    if(this.isLogin){
      document.getElementById('home-footer').style.borderTopLeftRadius = '15px'
      document.getElementById('home-footer').style.borderTopRightRadius = '15px'
    }
  },
  components: {
    badge
  },
  data(){
      return {
        appId: seo.config.appId,
        mode: seo.config.mode, // 自定义底部菜单
        isLogin: localStorage.getItem('token') ? true : false,
      }
  },
  props:{
    cartAmount: {
      default: 0,
      type: [Number, String]
    }
  },
  methods:{
    goHomePage(){
      if(this.$route.path === '/'){
        this.$emit('resetScrollTop')
      } else {
        this.$router.push('/')
      }
    },
    goBookingPage(){
      this.$router.push('/booking')
    },
    goShopPage(){
      if(this.$route.path === '/shop'){
        this.$emit('resetScrollTop')
      } else {
        this.$router.push('/shop')
      }
    },
    goCartPage(){
      if(this.$route.path === '/cart'){
        // this.$emit('resetScrollTop')
      } else {
        this.$router.push('/cart')
      }
    },
    goCategoryPage(){
      if(this.$route.path === '/category'){
        // this.$emit('resetScrollTop')
      } else {
        this.$router.push('/category')
      }
    },
    goChatPage(){
      if(this.$route.path === '/chat'){
        // this.$emit('resetScrollTop')
      } else {
        this.$router.push('/chat')
      }
    },
    goPrompt(){
      if(this.$route.path === '/prompt'){
        this.$emit('resetScrollTop')
      } else {
        this.$router.push('/prompt')
      }
    },
    goWeb(){
      if(this.$route.path === '/web'){
        this.$emit('resetScrollTop')
      } else {
        this.$router.push('/web')
      }
    },
    goUserCenter(){
      if(this.isLogin){
        if(this.$route.path === '/personal'){
          this.$emit('resetScrollTop')
        } else {
          this.$router.push('/personal')
        }
      } else {
        sessionStorage.setItem('fromPath', '/personal')
        this.$router.push({ name:'Login', params:{ text:'访主页' } })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.home-footer {
  position:fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  width: 100vw;
  display:flex;
  justify-content:space-between;
  align-items: center;
  background: #f3f3f3;
  height: 55px;
  box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.03);
  // line-height: 55px;
  color: #2c3e50;
  max-width: 1150px;
  left: 0;
  right: 0;
  margin: 0 auto;
  // border-top-left-radius: 15px;
  // border-top-right-radius: 15px;
  .divider {
    width: 1px;
    background: rgba(0,0,0,0.1);
    // height: 30px;
  }
  .home-page {
    width:50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .text {
      font-size: 10px;
    }
    img {
      padding-right: 0;
    }
    span {
      font-size: 16px;
      width: max-content;
    }
  }
  .user-center {
    width:50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 10px;
    }
    img {
      padding-right: 2px;
    }
    span {
      font-size: 16px;
    }
  }
  .safe-area {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
    background-color: #f3f3f3;
  }
}
</style>
