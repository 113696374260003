<template>
  <div class="badge-box">
      <div class="badge" v-if="amount > 0" :style="styleObj">
        <div class="amount-sm" :style="elementStyles" v-if="amount < 10">
          <div>{{ amount }} </div>
        </div>
        <div class="amount-md" :style="elementStyles" v-else-if="amount >= 10 && amount <= max">
          <div>{{ amount }} </div>
        </div>
        <div class="amount-lg" :style="elementStyles" v-else>
          <div>{{ max }}+ </div>
        </div>
      </div>
      <slot name="amount"></slot>
  </div>
</template>

<script>
export default {
name: 'Badge',
components: {
},
watch:{
  amount() {
    this.resize()
  }
},
data(){
  return {
    elementStyles: {
      '--width': this.width,
    },
    styleObj: {}
  }
},
created(){
  this.resize()
},
methods:{
  resize(){
    if(this.type === 'order'){
      this.styleObj = { 'right': this.amount < 10 ? '2px' : this.amount > 99 ? '-6px' : '-3px', top: '-6px' }
    } else {
      this.styleObj = { 'right': this.amount < 10 ? '-5px' : this.amount > 99 ? '-14px' : '-10px', top: '-3px' }
    }
  }
},
props:{
  amount:{
      default: 0,
      type: [String,Number]
  },
  max:{
    default: 99,
    type: [String,Number]
  },
  width: {
    default: '16px',
    type: String
  },
  type: {
    default: 'cart',
    type: String
  }
}
}
</script>

<style scoped lang="scss">
.badge-box {
  position: relative;
  .badge {
    position: absolute;
    z-index: 1;
    top: -8px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .amount-sm {
    width: var(--width, 16px);    // Use the prop value or default to '16px'
    height: var(--width, 16px);  // Use the prop value or default to '16px'
    line-height: var(--width, 16px); 
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 12px;
    background: #ee0a24;
    color: #fff;
  }
  .amount-md {
    width: var(--width, 16px);    // Use the prop value or default to '16px'
    height: var(--width, 16px);  // Use the prop value or default to '16px'
    line-height: var(--width, 16px); 
    padding: 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 12px;
    background: #ee0a24;
    color: #fff;
  }
  .amount-lg {
    width: var(--width, 16px);    // Use the prop value or default to '16px'
    height: var(--width, 16px);  // Use the prop value or default to '16px'
    line-height: var(--width, 16px); 
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 12px;
    background: #ee0a24;
    color: #fff;
  }
}
</style>
