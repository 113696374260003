<template>
    <div v-show="isShow">
        <div class="logout-dialog-box" @click="logout">
            <span v-if="isLogin">退出登录</span>
            <span v-else>您还未登录,去登陆？</span>
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
import seo from '../../../config/index'
export default {
  name: 'DeleteImg',
  components: {
  },
  activated() {
    this.isLogin = localStorage.getItem('token') ? true : false
  },
  data(){
      return {
          isShow: false,
          isLogin: localStorage.getItem('token') ? true : false
      }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
        logout(){
            // 判断是否是登录态
            if(this.isLogin){
                this.show()
                localStorage.clear()
                sessionStorage.clear()
                this.$store.commit('Set_IsLogin_State', false)
                this.$store.commit('Set_CartAmount_State', 0)
                this.$bus.$emit('logout')
                this.$emit('resetInputVal')
                if(seo.config.mode === 'shop'){
                    this.$router.push({ path:'/' })
                } else {
                    this.$router.push({ path:'/login' })
                }
            } else {
                this.$router.push({ path:'/login' })
            }
        },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.logout-dialog-box {
    width: calc(100% - 80px);
    max-width: 625px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    cursor: pointer;
    color: #000;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 1111;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
