<template>
  <div id="app" :class="[$store.state.userAgent === 'Mobile' ? 'app' : 'app-pc']">
    <van-loading color="#ea6f7b" size="30px" v-show="$store.state.loading && $route.path !=='/create' 
        && $route.path !=='/search' && !$route.path.includes('/about')"/>
    <!-- search页面只缓存无动画；首页缓存、动画 -->
    <transition :name="$route.path === '/search' || $route.path === '/search-result' || $route.path === '/' ? '' : ''">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"/>
      </keep-alive>
    </transition>
    <Main v-if="$store.state.userAgent === 'Mobile' && ($route.path === '/' || $route.path === '/cart'
     || $route.path === '/personal' || $route.path === '/category')" @resetScrollTop="resetScrollTop"
      :cartAmount="$store.state.cartAmount"></Main>
    <!-- 页面路由meta未开启，页面无缓存、无动画 -->
    <Header v-if="$route.path !== '/login'"></Header>
    <index-nav v-if="$route.path === '/create'"></index-nav>
    <router-view  v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>
import Header from '../src/components/nav/Header.vue'
import Main from './components/footer/Main.vue'
import { queryCartGoodsTotalAmount } from './api/api'

export default {
  mounted(){
    if(!(/Mobi|Android|iPhone/i.test(navigator.userAgent))){
      this.$store.commit('Set_UserAgent_State', 'PC')
    } else {
      this.initCartGoodsAmount()
      this.$store.commit('Set_UserAgent_State', 'Mobile')
    }
  },
  methods:{
      handleClick(tid){
          this.$router.push({ path:'/article', query:{ tid:tid } })
      },
      initCartGoodsAmount(){
          let isLogin = localStorage.getItem('token') ? true : false
          if(isLogin){
              let uid = localStorage.getItem('userInfo') ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
              queryCartGoodsTotalAmount({ uid: uid }).then((res)=>{
                this.$store.commit('Set_CartAmount_State', res.data.total_amount)
              })
          } else {
              this.$store.commit('Set_CartAmount_State', 0)
          }
      },
      resetScrollTop(){
        document.body.scrollTop && document.body.scrollTo({top: 0, behavior: 'smooth'})
        document.documentElement.scrollTop && document.documentElement.scrollTo({top: 0, behavior: 'smooth'})
      },
  },
  components: {
    Header,
    Main
  },
}
</script>

<style lang="scss">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f3f3f3;
}
.app-pc {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #121212;
}
.van-loading {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    font-size: 0;
    vertical-align: middle;
    -ms-transform: translate(-50%,-50%);
    z-index: 1;
}
.moveL-enter-active {
    transition: all 0.3s linear;
    transform: translateX(0%);
}
.moveL-enter {
    transform: translateX(-100%);
}
.moveL-leave-to {
    transform: translateX(-100%);
}
.moveR-enter-active,  .moveR-leave-active {
    transition: all 0.1s linear;
    transform: translateX(0);
}
.moveR-enter,  .moveR-leave {
    transform: translateX(100%);
}
.moveR-leave-to{
    transform: translateX(100%);
}
</style>
