import axios from 'axios'
import { Toast } from 'vant'

const service = axios.create({
    baseURL:"/v1",
    // baseURL:'http://127.0.0.1:10006/v1',
    // baseURL:'http://149.28.75.98:10006/v1',
    timeout: 60000,
    withCredentials: true,
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded'
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("token")
    },
    //headers:{'language':localStorage.getItem('locale') === 'cn' ? 1 : 2}
    // headers:{'t':parseInt(new Date().getTime()/1000),'token':sessionStorage.getItem('token')}
})
// 请求拦截器
service.interceptors.request.use(
    config => {  
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// axios 请求处理超时处理
service.interceptors.response.use(
    function (response) {
      if (response.data.status == 401) {
        // token过期，需要登录
        console.log("token过期")
        Toast('token已过期，请重新登录')
        // localStorage.removeItem("token") 
        this.$router.push("/").catch(err => { console.log(err) }) // catch解决路由重复点击报错
        return false
      }
      return response
    },
    (err) => {
      if (err.message === "Network Error") {
        Toast('网络异常，请检查网络连接情况')
      }
      return Promise.reject(err.response.data)
    }
)
export default service