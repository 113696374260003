import Vue from 'vue'
import Vuex from 'vuex'
import { queryUserByPhoneNum } from '@/api/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    userAgent:'', // 'PC' 'Mobile'
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {},
    isLogin: localStorage.getItem('token') ? true : false,
    showLabel: false,
    cartAmount: 0, // 购物车徽标
  },
  mutations: {
    Set_Loading_State(state, loading){
        state.loading = loading
    },
    Set_UserInfo_State(state, param){
      state.userInfo = param.userInfo
    },
    Set_UserAgent_State(state, userAgent){
      state.userAgent = userAgent
    },
    Set_IsLogin_State(state, isLogin){
      state.isLogin = isLogin
    },
    Set_ShowLabel_State(state, showLabel){
      state.showLabel = showLabel
    },
    Set_CartAmount_State(state, cartAmount){
      state.cartAmount = cartAmount
    }
  },
  actions: {
    getUserInfo(context){
      queryUserByPhoneNum({ phoneNum: localStorage.getItem('phoneNumber') }).then(res=>{
          localStorage.setItem('userInfo', JSON.stringify(res.data.data))
          context.commit('Set_UserInfo_State', { userInfo:res.data.data })
      })
    },
  },
  modules: {
  }
})
