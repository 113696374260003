const articleTitle = '使用文章标题#'
const articleContent = '使用文章内容'
const personalName = '登录用户或文章作者昵称#'
const welcomeTo = '欢迎来到'
// 测试服
// const beianId = '琼ICP备2022008915号'
// const homeUrl = 'https://emer-nav.cn'

// 正式服
const beianId = '粤ICP备2023104090号'
const homeUrl = 'https://meifuzhu.com'
/* 以上常量不可修改 */
/* 请在src/assets/images文件夹中替换logo.png*/
/* 请在public文件夹中favicon.ico*/
/* 新注册用户默认关注uid为33的官方账号 */

exports.config = {
    appName: '媒辅助',
    appId: 'mediahelper',
    mode: '',
    homeUrl: homeUrl,
    beianId: beianId, // 备案号
    shopName: '媒辅助的店铺',
    requireInvitationCode: true, // 是否启动内测邀请码
    seoPage:{
        // 默认
        default:{
            title: '没辅助就找媒辅助-媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,aigc应用导航,aigc工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质aigc应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        },
        // 首页
        homePage:{
            title: '没辅助就找媒辅助-媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质ai应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        },
        // 聊个小天AI
        chatPage:{
            title: '聊个小天-媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质ai应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        },
        // 商城主页
        shopPage:{
            title: '内容创作课程-媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质ai应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        },
        // 文章详情页
        articlePage:{
            title: articleTitle +'媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: articleContent
        },
        // 个人主页、作者主页
        personalPage:{
            title: personalName + '的个人主页',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: welcomeTo + personalName + '的个人主页,快来和我一起参与媒辅助社区吧~'
        },
        // 商品详情主页
        shopDescPage:{
            title: '',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质ai应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        },
        aboutPage:{
            title: '关于媒辅助',
            keywords: 'ai导航,智能聊天机器人,智能聊天ai导航,ai应用导航,ai工具导航,chatgpt,,智能聊天助手,聊天机器人,ai聊天,合伙人,会员,国内版chatgpt,提示词,写文案',
            description: '精选全网优质ai应用,提供国内版免翻墙chatgpt聊天,提供相关提示词案例,在这里你可进行朋友圈文案创作,查专业知识,小红书文案创作,写淘宝评论,写周报日报,写短视频脚本,写节日祝福,中英文翻译,写简历,写剧本,写材料,找灵感,找创业思路。'
        }
    },
    aboutUs: [{
        question:'',
        answer:''
    },{
        question:'',
        answer:''
    }],
    commonQA: [{
        question:'',
        answer:''
    },{
        question:'',
        answer:''
    }],
    logo: homeUrl + '/public/images/logo.png',
    poster:[{
        url: homeUrl + '/public/images/poster618.png'
    },{
        url: homeUrl + '/public/images/poster622.png'
    }],
    wxConfig:{
        appId:'wxa0196ed6c7eea972',
        // 首页微信分享
        homePage:{
            title: '媒辅助社区',
            desc: '这里提供最全面的AIGC赛道资讯~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 文章详情页微信分享
        articlePage:{
            title: '媒辅助社区',
            desc: '',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 商场首页微信分享
        shopPage:{
            title: '媒辅助的店铺',
            desc: '快来和我一起逛逛媒辅助商店吧~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 聊个小天微信分享
        chatPage:{
            title: '聊个小天',
            desc: '您已成为受邀用户，快来开启您的AI聊天吧~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // AI工具微信分享
        promptPage:{
            title: 'AI工具',
            desc: '快来使用AI工具进行内容生成吧~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // AI工具详情微信分享
        outputPage:{
            title: '', // 组件内配置工具名称
            desc: '该工具由媒辅助提供，欢迎使用~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // AI导航微信分享
        webPage:{
            title: '媒辅助',
            desc: '快来媒辅助找到适合你的AI工具吧~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 开通会员微信分享
        activesvipPage:{
            title: '开通会员',
            desc: '对话更安心，创作更高效~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 开通合伙人微信分享
        partnerPage:{
            title: '开通合伙人',
            desc: '欢迎有识之士一起加入AIGC赛道~',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 合伙人后台微信分享
        partnerAdminPage:{
            title: '合伙人后台',
            desc: '后台管理系统',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/logo-media-helper-bg.png'
        },
        // 个人主页、作者主页微信分享
        personalPage:{
            title: personalName +'的个人主页-媒辅助',
            desc: '',
            link: homeUrl,
            imgUrl: homeUrl + '/public/images/avatar.png'
        }
    },
    // 搜索发现
    searchLists:[{
        id:'draftBox',
        text: '草稿箱'
    },{
        id:'chatRobot',
        text: '聊个小天'
    },
    // {
    //     id: 'download',
    //     text: '下载App'
    // },
    {
        id:'createArticle',
        text: '创建文章'
    },
    // {
    //     id:'shop',
    //     text: '好物推荐'
    // },
    {
        id:'vision',
        text:'愿景与目标',
    },{
        id:'personal',
        text:'前往个人主页',
    },{
        id:'logout',
        text:'退出登录',
    }],
}